import React from "react";

function DefaultPage() {
  return (
    <div>
      <h1>Not for mobile.</h1>
      {/* Add your default page content here */}
    </div>
  );
}

export default DefaultPage;
