import { useTranslation } from "react-i18next";

function withTranslate(Component) {
  return function TranslatedComponent(props) {
    const { t } = useTranslation();
    return <Component {...props} t={t} />;
  };
}

export default withTranslate;
