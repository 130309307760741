import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Toolbar, AppBar, IconButton, Icon, Menu } from "@mui/material";
import { MDBox, MDMenuItem } from "components";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "components/Main/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar } from "context";
import ReactFlagsSelect from "react-flags-select";
import { useAuth } from "helper";
import { Logout } from "services/Login";

function DashboardNavbar({ absolute, light, isMini, title }) {
  const { user, removeUserData } = useAuth();
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);

  const { t } = useTranslation();
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem("language") === "en-US" ? "US" : "HR");

    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, false);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const changeLanguage = (code) => {
    setSelectedLanguage(code === "US" ? "en-US" : "HR");
    i18n.changeLanguage(code === "US" ? "en-US" : code.toLowerCase());
    localStorage.setItem("language", code === "US" ? "en-US" : code.toLowerCase());
  };

  const getUserName = () => `${user?.firstname} ${user?.lastname}`;

  const logOut = () => {
    Logout().then(() => {
      removeUserData();
      navigate(user?.code ? `/?destinationRef=${user.code}` : "/");
    });
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MDMenuItem icon={<Icon>logout</Icon>} title={t("logout")} onClickAction={logOut} />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          className="navbar-title"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          {t(title)}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox
              color="inherit"
              className="navbar-title"
              mb={{ xs: 1, md: 0 }}
              sx={(theme) => navbarRow(theme, { isMini })}
            >
              {getUserName()}
            </MDBox>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <Icon>account_circle</Icon>
            </IconButton>
            {renderMenu()}
            <ReactFlagsSelect
              selected={selectedLanguage === "en-US" ? "US" : selectedLanguage}
              countries={["US", "HR"]}
              customLabels={{ US: t("english"), HR: t("croatian") }}
              onSelect={changeLanguage}
            />
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  title: "",
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
};

export default DashboardNavbar;
