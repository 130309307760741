import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import ReactCodeInput from "react-code-input";
import { Card, IconButton, InputAdornment, styled } from "@mui/material";
import { MDBox, MDTypography, MDLoadingButton, MDInput, MDButton } from "components";
import CoverLayout from "pages/Public/Login/layout";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Ask2fa, Submit2fa } from "services/Login";
import { DevTool } from "@hookform/devtools";
import { useSnackbar } from "helper";
import CookieConsent from "react-cookie-consent";

const StyledLink = styled(Link)`
  color: #1ea7fd;
`;

const CookieText = styled("span")({
  fontSize: "1rem",
});

function Login() {
  const [codeValue, setCodeValue] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [codeBox, setCodeBox] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm({ mode: "onBlur" });
  const { errors } = formState;
  const [searchParams] = useSearchParams();
  const { showSuccessSnackbar } = useSnackbar();

  useEffect(() => {
    if (searchParams.get("resetSent") === "true") {
      showSuccessSnackbar({ content: "resetpasswordmessage" });
    }
    if (searchParams.get("passwordChanged") === "true") {
      showSuccessSnackbar({ content: "passwordresetsuccess" });
    }
  }, [searchParams]);

  const getDestinationRef = () => searchParams.get("destinationRef") || "iam";

  const onSubmit = (data) => {
    setIsLoading(true);
    Ask2fa({
      Username: data.email,
      Password: data.password,
      DestinationRef: getDestinationRef(),
    }).then((response) => {
      if (response.status === 200) {
        setCodeBox(true);
        setIsLoading(false);
      } else {
        setError(t("invalidusernameorpassword"));
        setIsLoading(false);
      }
    });
  };

  const confirmCodeBox = (event) => {
    setIsLoading(true);
    event.preventDefault();
    Submit2fa({
      OTP: codeValue,
    }).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        window.location = response.headers.redirecturi;
      } else {
        setError(t("invalidusernameorpassword"));
        setIsLoading(false);
      }
    });
    setIsLoading(false);
  };

  const handleCodeChange = (value) => {
    setCodeValue(value);
  };

  const renderCodeBox = () => (
    <MDBox>
      <ReactCodeInput type="number" fields={6} value={codeValue} onChange={handleCodeChange} />
      <MDBox mt={4} mb={1}>
        <MDLoadingButton
          variant="gradient"
          type="button"
          onClick={(event) => confirmCodeBox(event)}
          color="dark"
          isLoading={isLoading}
          fullWidth
          autoFocus={false}
        >
          {t("submit")}
        </MDLoadingButton>
      </MDBox>
    </MDBox>
  );

  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {getDestinationRef().toUpperCase()}
          </MDTypography>
          {!codeBox ? (
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your email and password to Sign In
            </MDTypography>
          ) : (
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your OTP passcode we just sent to your email
            </MDTypography>
          )}
        </MDBox>
        <MDBox pt={4} pb={3} px={3} position="relative">
          {!codeBox ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <MDBox mb={2}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      pattern: {
                        value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                        message: t("signin.invalidEmailFormat"),
                      },
                      required: t("signin.emailRequired"),
                    }}
                    render={({ field }) => (
                      <MDInput
                        variant="standard"
                        id="email"
                        type="email"
                        label="Email"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.email}
                        value={field.value || ""}
                        onChange={(event) => field.onChange(event.target.value)}
                        onBlur={field.onBlur}
                      />
                    )}
                    defaultValue=""
                  />
                  <MDBox sx={{ height: 0 }}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      {errors.email && t(errors.email.message)}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mb={2}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: t("login.passwordRequired"),
                    }}
                    render={({ field }) => (
                      <MDInput
                        style={{ backgroundColor: "white" }}
                        type={showPassword ? "text" : "password"}
                        label={t("password")}
                        variant="standard"
                        fullWidth
                        InputProps={{
                          endAdornment: field.value.length > 0 && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.password}
                        value={field.value || ""}
                        onChange={(event) => field.onChange(event.target.value)}
                        onBlur={field.onBlur}
                        {...field}
                      />
                    )}
                    defaultValue=""
                  />
                </MDBox>
                <MDBox sx={{ height: 0 }} mt={-2}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors.password && t(errors.password.message)}
                  </MDTypography>
                </MDBox>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {error}
                </MDTypography>

                <MDBox mt={4} mb={1}>
                  <MDLoadingButton
                    isLoading={isLoading}
                    variant="gradient"
                    type="submit"
                    color="dark"
                    fullWidth
                    disabled={isLoading}
                  >
                    {t("login")}
                  </MDLoadingButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    {t("forgotpassword")}
                    <MDTypography
                      component={Link}
                      to={{
                        pathname: "reset-password",
                        search: `?destinationRef=${getDestinationRef()}`,
                      }}
                      variant="button"
                      color="dark"
                      fontWeight="medium"
                      textGradient
                    >
                      {t("resetpassword.reset")}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </div>
            </form>
          ) : (
            <MDBox mt={4} mb={1} display="flex" justifyContent="center">
              {renderCodeBox()}
            </MDBox>
          )}
        </MDBox>
      </Card>
      <CookieConsent
        ButtonComponent={MDButton}
        buttonText={t("cookies.bar.btnTxt")}
        cookieName="IamConsent"
        customButtonProps={{
          style: {
            backgroundColor: "inherit",
            border: "1px solid white",
            bottom: "0",
            color: "white",
            fontSize: "1rem",
            margin: "10px",
            position: "absolute",
            right: "0",
            textTransform: "capitalize",
          },
          variant: "contained",
        }}
        expires={30}
      >
        <CookieText>
          <Trans
            i18nKey="cookies.bar.info"
            components={[
              <br />,
              <StyledLink to={`/cookie-policy?destinationRef=${getDestinationRef()}`} />,
            ]}
          />
        </CookieText>
      </CookieConsent>
      <DevTool control={control} />
    </CoverLayout>
  );
}

export default Login;
