import axios from "axios";
import { iamURL } from "config";

export async function InitAuth() {
  const path = "destination/initauth";
  const url = `${iamURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
    redirectURI: window.location.href,
  });

  try {
    const initAuthResponse = await axios.post(url, "", {
      headers: getHeaders(),
      withCredentials: true,
    });
    return initAuthResponse;
  } catch (error) {
    return error;
  }
}

export async function Auth() {
  const path = "destination/auth";
  const url = `${iamURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  try {
    const authResponse = await axios.post(url, "", {
      headers: getHeaders(),
      withCredentials: true,
    });
    if (authResponse.status === 200) {
      return authResponse;
    }
  } catch (error) {
    return error;
  }
}

export async function Ask2fa(body) {
  const path = "login/ask2fa";
  const url = `${iamURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  try {
    return await axios.post(url, body, {
      headers: getHeaders(),
      withCredentials: true,
    });
  } catch (error) {
    return error;
  }
}

export async function Submit2fa(body) {
  const path = "login/submit2fa";
  const url = `${iamURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  try {
    return await axios.post(url, body, {
      headers: getHeaders(),
      withCredentials: true,
    });
  } catch (error) {
    return error;
  }
}

export async function Logout() {
  const path = "destination/logout";
  const url = `${iamURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  try {
    const response = await axios.post(url, "", {
      headers: getHeaders(),
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function Ask2Reset(body) {
  const path = "reset/askreset";
  const url = `${iamURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  try {
    return await axios.post(url, body, {
      headers: getHeaders(),
      withCredentials: true,
    });
  } catch (error) {
    return error;
  }
}

export async function ResetPassword(body, session) {
  const path = "reset/confirmreset";
  const url = `${iamURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
    reset_session: session,
  });

  try {
    return await axios.post(url, body, {
      headers: getHeaders(),
      withCredentials: true,
    });
  } catch (error) {
    return error;
  }
}
