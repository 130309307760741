import { useEffect } from "react";
import { useAuth } from "helper";
import { useNavigate } from "react-router-dom";

function PublicRoute({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user != null) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return children;
}

export default PublicRoute;
