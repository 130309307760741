import React, { useState, useMemo } from "react";
import SnackbarContext from "context/snackbarContext";
import { MDSnackbar } from "components";
import { useTranslation } from "react-i18next";

const DEFAULT_SNACKBAR_CONFIG = {
  color: "success",
  icon: "",
  title: "",
  content: "",
  dateTime: "",
  bgWhite: true,
};

function SnackbarProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState(DEFAULT_SNACKBAR_CONFIG);
  const { t } = useTranslation();

  const showSnackbar = (config) => {
    setIsOpen(true);
    setSnackbarConfig((prevConfig) => ({ ...prevConfig, ...config }));
  };

  const showSuccessSnackbar = ({ title = "success", content }) => {
    showSnackbar({
      color: "success",
      icon: "check",
      title: t(title),
      content: t(content),
    });
  };

  const showInfoSnackbar = ({ title = "info", content }) => {
    showSnackbar({
      color: "info",
      icon: "info",
      title: t(title),
      content: t(content),
    });
  };

  const showErrorSnackbar = ({ title = "error", content }) => {
    showSnackbar({
      color: "error",
      icon: "warning",
      title: t(title),
      content: t(content),
    });
  };

  const closeSnackbar = () => {
    setIsOpen(false);
    setSnackbarConfig(DEFAULT_SNACKBAR_CONFIG);
  };

  const snackbarValue = useMemo(
    () => ({
      showSnackbar,
      showSuccessSnackbar,
      showInfoSnackbar,
      showErrorSnackbar,
      closeSnackbar,
    }),
    [showSnackbar, showSuccessSnackbar, showInfoSnackbar, showErrorSnackbar, closeSnackbar]
  );

  return (
    <SnackbarContext.Provider value={snackbarValue}>
      {children}
      <MDSnackbar open={isOpen} onClose={closeSnackbar} close={closeSnackbar} {...snackbarConfig} />
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;
