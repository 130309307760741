import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { MDButton, MDLoadingButton } from "components";
import { useTranslation, Trans } from "react-i18next";

const COMPONENTS = {
  b: "b",
};

function MDConfirmDialog({
  open,
  setOpen,
  title,
  content,
  onConfirm,
  className,
  isLoading,
  confirmLabel = "confirm",
}) {
  const { t } = useTranslation();
  const handleClose = (event, reason) => {
    if (isLoading && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleConfirm = async () => {
    await onConfirm();
    handleClose();
  };
  const { components, ...contentProps } = content;
  return (
    <Dialog
      className={className}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="confirm-dialog-content">
          <Trans
            {...contentProps}
            components={
              components != null
                ? components.map((i) => {
                    const TagName = COMPONENTS[i];
                    return <TagName />;
                  })
                : []
            }
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6} />
          <Grid item xs={3} pr={1}>
            <MDButton
              fullWidth
              color="dark"
              variant="outlined"
              onClick={handleClose}
              disabled={isLoading}
            >
              {t("cancel")}
            </MDButton>
          </Grid>
          <Grid item xs={3}>
            <MDLoadingButton
              autoFocus
              fullWidth
              color="dark"
              variant="gradient"
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              {t(confirmLabel)}
            </MDLoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default MDConfirmDialog;
