import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MDButton, MDTypography } from "components";

function DeleteAction({ open, closed, type, setConfirm }) {
  const { t } = useTranslation();

  const toggleConfirm = () => {
    setConfirm(true);
    closed();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={closed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          {type === "delete" && (
            <MDTypography variant="h2" className="deleteTextTitle" fontWeight="regular">
              {t("delete")}
            </MDTypography>
          )}
          {type === "reset" && (
            <MDTypography variant="h2" className="deleteTextTitle" fontWeight="regular">
              {t("reset")}
            </MDTypography>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MDTypography variant="h6" fontWeight="regular">
              {type === "delete" && t("projects.deleteform.deletetext")}
            </MDTypography>
            <MDTypography variant="h6" fontWeight="regular">
              {type === "reset" && t("usermanagment.general.ressetpassrequest")}
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton className="buttonNo" onClick={closed} size="large" color="light">
            {t("projects.deleteform.no")}
          </MDButton>
          <MDButton className="buttonYes" onClick={toggleConfirm} size="large">
            {t("projects.deleteform.yes")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteAction;
