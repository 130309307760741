import { useHTTPClient } from "helper";

const ClientsService = () => {
  const { get } = useHTTPClient();

  const getAllClients = () => get("Client/getAll");

  const getClientById = (id) => get(`Client/getById?clientId=${id}`);

  return {
    getAllClients,
    getClientById,
  };
};

export default ClientsService;
