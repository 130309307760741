import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MDLoadingButton } from "components";
import { withTranslate } from "hoc";
import { Logout } from "services/Login";
import { useAuth, useSnackbar } from "helper";

function AuthorizationChangedDialog({ t, open, close }) {
  const [isLoading, setIsLoading] = useState(false);
  const { removeUserData, user } = useAuth();
  const navigate = useNavigate();
  const { showErrorSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const { status } = await Logout();
      if (status === 200) {
        removeUserData();
        close();
        navigate(user?.code ? `/?destinationRef=${user.code}` : "/");
      }
    } catch (e) {
      showErrorSnackbar({ content: "apierrorpost" });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>{t("authorizationchangedtitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("authorizationchangedtext")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDLoadingButton
          color="dark"
          variant="gradient"
          onClick={handleLogout}
          isLoading={isLoading}
        >
          OK
        </MDLoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default withTranslate(AuthorizationChangedDialog);
