import { useState } from "react";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import { MDBox, MDTypography, MDInput, MDLoadingButton } from "components";
import CoverLayout from "pages/Public/Login/layout";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Ask2Reset } from "services/Login";

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { errors } = formState;
  const [searchParams] = useSearchParams();

  const getDestinationRef = () => searchParams.get("destinationRef") || "iam";

  const onSubmit = (data) => {
    setIsLoading(true);
    Ask2Reset({
      Username: data.email,
      DestinationRef: getDestinationRef(),
    }).then(() => {
      setIsLoading(false);
      navigate(`/?destinationRef=${getDestinationRef()}&resetSent=true`);
    });
  };
  return (
    <CoverLayout coverHeight="50vh">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            {t("resetpassword.reset")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={2}>
            {t("resetpassword.message")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MDBox component="div" role="form">
              <MDBox mb={4}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                      message: t("login.invalidEmailFormat"),
                    },
                    required: t("login.emailRequired"),
                  }}
                  render={({ field }) => (
                    <MDInput
                      variant="standard"
                      id="email"
                      type="email"
                      label="Email"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.email}
                      value={field.value || ""}
                      onChange={(event) => field.onChange(event.target.value)}
                      onBlur={field.onBlur}
                    />
                  )}
                  defaultValue=""
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDLoadingButton
                  variant="gradient"
                  color="dark"
                  isLoading={isLoading}
                  fullWidth
                  type="submit"
                >
                  {t("resetpassword.send")}
                </MDLoadingButton>
              </MDBox>

              <MDBox mt={2} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  {t("resetpassword.return")}
                  {"  "}
                  <MDTypography
                    component={Link}
                    to={{
                      pathname: "/*",
                      search: `?destinationRef=${getDestinationRef()}`,
                    }}
                    variant="button"
                    color="dark"
                    fontWeight="medium"
                    textGradient
                  >
                    {t("resetpassword.login")}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
