import React, { useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth, useAuthorizationChangedDialog } from "helper";
import HTTPClientContext from "context/httpClientContext";
import { baseURL } from "config";

const getUrl = (path) => `${baseURL}/${path}`;

const getHeaders = (actionPermissions) => ({
  "Content-Type": "application/json",
  actionPermissions,
});

function HTTPClientProvider({ children }) {
  const { user, removeUserData } = useAuth();
  const navigate = useNavigate();
  const { showAuthorizationChangedDialog } = useAuthorizationChangedDialog();

  const handleUnauthorized = () => {
    removeUserData();
    navigate(user?.code ? `/?destinationRef=${user.code}` : "/");
  };

  const get = async (path, cancelToken) => {
    try {
      return await axios.get(getUrl(path), {
        headers: getHeaders(user?.actionPermissions),
        withCredentials: true,
        cancelToken,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const post = async (path, body) => {
    try {
      return await axios.post(getUrl(path), body, {
        headers: getHeaders(user?.actionPermissions),
        withCredentials: true,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const put = async (path, body) => {
    try {
      return await axios.put(getUrl(path), body, {
        headers: getHeaders(user?.actionPermissions),
        withCredentials: true,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const del = async (path) => {
    try {
      return await axios.delete(getUrl(path), {
        headers: getHeaders(user?.actionPermissions),
        withCredentials: true,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const httpClientValue = useMemo(() => ({ get, post, put, del }), [get, post, put, del]);

  return (
    <HTTPClientContext.Provider value={httpClientValue}>{children}</HTTPClientContext.Provider>
  );
}

export default HTTPClientProvider;
