import { useHTTPClient } from "helper";

const UsersService = () => {
  const { get, post, del, put } = useHTTPClient();

  const getAllUsers = () => get("User/getAll?page=1&recordsToTake=1000");

  const getUserById = (userId) => get(`User/getById?userId=${userId}`);

  const getAllTenants = () => get("Tenant/getAll");

  const getAllClients = (tenantId) => get(`Client/getAll?tenantId=${tenantId}`);

  const createUser = (data) => post("User/create", data);

  const deleteUser = (userId, changeUserId) =>
    del(`User/delete?userId=${userId}&changeUserId=${changeUserId}`);

  const resetPassword = (data) => post(`reset/iamaskreset`, data);

  const updateUser = (data) => put("User/update", data);

  return {
    getAllUsers,
    getUserById,
    getAllTenants,
    getAllClients,
    createUser,
    deleteUser,
    resetPassword,
    updateUser,
  };
};

export default UsersService;
