/* eslint-disable react/prop-types */
import { MDBox } from "components";

function DataTableBodyCell({ noBorder, align, children, ...otherProps }) {
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      })}
      {...otherProps}
    >
      <MDBox display="inline-block" width="max-content" color="text">
        {children}
      </MDBox>
    </MDBox>
  );
}

export default DataTableBodyCell;
