import { DashboardLayout } from "components";

function defaultTemplate(Component) {
  return function LayoutComponent({ title, ...otherProps }) {
    return (
      <DashboardLayout title={title}>
        <Component {...otherProps} />
      </DashboardLayout>
    );
  };
}

export default defaultTemplate;
