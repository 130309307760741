import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "components/Main/PageLayout";

// CoverLayout component

function CoverLayout({ children }) {
  return (
    <PageLayout>
      <MDBox width="calc(100% - 2rem)" borderRadius="xl" mx={2} my={2} pt={6} pb={28} />
      <MDBox mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default CoverLayout;
