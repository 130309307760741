import React, { useState, useMemo } from "react";
import AuthorizationChangedDialogContext from "context/authorizationChangedDialogContext";
import { AuthorizationChangedDialog } from "components";

function AuthorizationChangedDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const showAuthorizationChangedDialog = () => setIsOpen(true);

  const closeAuthorizationChangedDialog = () => setIsOpen(false);

  const authorizationChangedDialogValue = useMemo(
    () => ({
      showAuthorizationChangedDialog,
    }),
    [showAuthorizationChangedDialog]
  );

  return (
    <AuthorizationChangedDialogContext.Provider value={authorizationChangedDialogValue}>
      {children}
      <AuthorizationChangedDialog open={isOpen} close={closeAuthorizationChangedDialog} />
    </AuthorizationChangedDialogContext.Provider>
  );
}

export default AuthorizationChangedDialogProvider;
