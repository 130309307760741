import { useAuth } from "helper";
import { styled } from "@mui/system";

const ActionWrapper = styled("span")(({ style }) => ({
  ...style,
}));

/**
 * Authorization component that wraps children and controls their visibility or display based on user's action permissions.
 *
 * @param {string} externalId - The external ID used to check against user's action permissions.
 * @param {("visibility"|"display")} appearance - The CSS property to control, can be 'visibility' or 'display'.
 * @param {React.ReactNode} children - The children nodes to be rendered inside the ActionWrapper.
 * @returns {React.ReactNode} The ActionWrapper component with children and controlled visibility or display.
 */
function Authorization({ externalId, appearance = "visibility", children }) {
  const { user } = useAuth();
  const isVisible = user?.actionPermissions?.toLowerCase().includes(externalId.toLowerCase());
  let appearanceValue;
  if (appearance === "visibility") appearanceValue = isVisible ? "visible" : "hidden";
  else appearanceValue = isVisible ? "inline" : "none";

  return <ActionWrapper style={{ [appearance]: appearanceValue }}>{children}</ActionWrapper>;
}

export default Authorization;
