/* eslint-disable no-undef */
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
} from "react-table";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Icon,
  Autocomplete,
  IconButton,
  TableCell,
  Collapse,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  MDBox,
  MDTypography,
  MDInput,
  MDPagination,
  DataTableHeadCell,
  DataTableBodyCell,
} from "components";
import { useTranslation } from "react-i18next";

function DataTable({
  tableOptions,
  setTableOptions,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  isLoading,
  footerEnabled,
  canSearch,
  getTableRowProps = () => ({}),
  getCustomCellProps = () => ({}),
  isCollapsible,
  CollapsibleComponent,
  searchTooltip = "",
  AdditionalFilterComponent,
  hideCollapseColumn,
  subtractTableHeight = "17vh",
}) {
  const entries = ["10", "20", "50", "100", "500", "1000"];
  const columns = useMemo(() => table.columns, [table]);
  const { t } = useTranslation();

  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      tableOptions,
      getTrProps: getTableRowProps,
      getCellProps: getCustomCellProps,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    getTrProps,
    getCellProps,
    toggleAllRowsExpanded,
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(tableOptions.pageSize || 1000), [tableOptions.pageSize]);

  // Set the entries per page value based on the select value
  const entiresChanged = (value) => {
    setTableOptions({ pageSize: value });
    setPageSize(value);
  };

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // Render the paginations
  let options;

  if (pageIndex === 0) {
    options = pageOptions.slice(0, 3);
  } else if (pageIndex === pageOptions.length - 1) {
    options = pageOptions.slice(pageIndex - 2, pageIndex + 1);
  } else {
    options = pageOptions.slice(pageIndex - 1, pageIndex + 2);
  }

  const renderPagination = options.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer className="dataTable" style={{ height: "100%" }}>
      {canSearch && (
        <Grid container justifyContent="flex-end">
          {AdditionalFilterComponent != null ? <AdditionalFilterComponent /> : null}
          <Grid item xs={1.5} pr={2} pb={2}>
            <Tooltip title={t(searchTooltip)} placement="top">
              <MDInput
                placeholder={t("datatable.search")}
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      )}

      <div
        style={{ height: `calc(100% - ${subtractTableHeight})`, flexGrow: 1, overflowY: "auto" }}
      >
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {isCollapsible && !hideCollapseColumn ? <DataTableBodyCell width={100} /> : null}
                {headerGroup.headers.map((column) =>
                  !column.hide ? (
                    <DataTableHeadCell
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ) : null
                )}
              </TableRow>
            ))}
          </MDBox>

          {rows.length === 0 ? (
            <TableBody>
              <TableRow>
                <DataTableBodyCell className="noDataCell">
                  {isLoading ? (
                    <div className="spinner" />
                  ) : (
                    <div className="noDataContainer">
                      <Icon className="noDataIcon">do_disturb</Icon>
                      <span className="noDataText">No data available</span>
                    </div>
                  )}
                </DataTableBodyCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody {...getTableBodyProps()}>
              {page.map((row, key) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.original.id}>
                    {isLoading ? (
                      <TableRow>
                        <DataTableBodyCell>
                          <div className="spinner" />
                        </DataTableBodyCell>
                      </TableRow>
                    ) : (
                      <>
                        <TableRow {...row.getRowProps()} {...getTrProps(row, false)}>
                          {isCollapsible && !hideCollapseColumn ? (
                            <DataTableBodyCell>
                              <IconButton
                                size="medium"
                                onClick={() => {
                                  if (!row.isExpanded) toggleAllRowsExpanded(false);
                                  row.toggleRowExpanded();
                                }}
                              >
                                <Icon>
                                  {row.isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                                </Icon>
                              </IconButton>
                            </DataTableBodyCell>
                          ) : null}
                          {row.cells.map((cell) =>
                            !cell.column.hide ? (
                              <DataTableBodyCell
                                noBorder={rows.length - 1 === key}
                                align={cell.column.align ? cell.column.align : "left"}
                                {...cell.getCellProps()}
                                {...getCellProps(cell, tableInstance)}
                              >
                                {cell.render("Cell")}
                              </DataTableBodyCell>
                            ) : null
                          )}
                        </TableRow>
                        {isCollapsible ? (
                          <TableRow {...getTrProps(row, true)}>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                              <Collapse in={row.isExpanded} timeout="auto" unmountOnExit>
                                <CollapsibleComponent key={row.id} {...row} />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
      {footerEnabled && (
        <div className="tableFooter">
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            height="10vh"
          >
            {pageOptions.length > 1 && (
              <MDPagination variant={pagination.variant ? pagination.variant : "gradient"}>
                <MDPagination item onClick={() => gotoPage(0)} disabled={pageIndex === 0}>
                  <Icon className="icons">first_page</Icon>
                </MDPagination>
                {renderPagination}
                <MDPagination
                  item
                  onClick={() => gotoPage(pageOptions.length - 1)}
                  disabled={pageIndex === pageOptions.length - 1}
                  color={pageIndex === pageOptions.length - 1 ? "dark" : "light"}
                >
                  <Icon className="icons">last_page</Icon>
                </MDPagination>
              </MDPagination>
            )}

            {rows.length !== 0 ? (
              <MDBox style={{ marginLeft: "auto" }} p={3}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
                >
                  {showTotalEntries && (
                    <MDTypography
                      variant="button"
                      color="secondary"
                      fontWeight="regular"
                      sx={{
                        textTransform: "none !important",
                        fontWeight: "400 !important",
                        lineHeight: "1.5 !important",
                      }}
                    >
                      {t("datatable.showing")} {entriesStart} {t("datatable.to")} {entriesEnd}{" "}
                      {t("datatable.of")} {rows.length} {t("datatable.entries")}
                    </MDTypography>
                  )}
                  {tableOptions.pageSize && (
                    <Autocomplete
                      disableClearable
                      value={pageSize.toString()}
                      options={entries}
                      noOptionsText={t("autocomplete.nooptions")}
                      onChange={(event, newValue) => {
                        entiresChanged(parseInt(newValue, 10));
                      }}
                      size="small"
                      sx={{ width: "5rem", marginRight: "1rem", marginLeft: "1rem" }}
                      renderInput={(params) => <MDInput {...params} />}
                    />
                  )}
                </MDBox>
              </MDBox>
            ) : null}
          </MDBox>
        </div>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  tableOptions: { pageIndex: 0, pageSize: 1000 },
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  isLoading: false,
  footerEnabled: true,
  canSearch: false,
  handleSearch: () => {},
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  tableOptions: PropTypes.oneOfType([
    PropTypes.shape({
      pageSize: PropTypes.number,
      pageIndex: PropTypes.number,
    }),
    PropTypes.bool,
  ]),
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
  }),
  isSorted: PropTypes.bool,
  isLoading: PropTypes.bool,
  footerEnabled: PropTypes.bool,
  canSearch: PropTypes.bool,
  handleSearch: PropTypes.func,
};

export default DataTable;
