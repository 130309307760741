import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { List, Divider, Link } from "@mui/material";
import { Authorization, MDBox, MDTypography, SidenavCollapse, IAMLogo } from "components";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import SidenavRoot from "./SidenavRoot";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav } = controller;
  const location = useLocation();
  const { t } = useTranslation();
  const collapseName = location.pathname.replace("/", "");
  let textColor = "white";

  if (transparentSidenav || whiteSidenav) {
    textColor = "dark";
  }

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resi n<n    ze", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, menuPermission }) => {
      let returnValue;
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={t(name)}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={t(name)} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
            sx={{
              fontSize: "0.75rem !important",
              fontWeight: "700 !important",
              lineHeight: "1.25 !important",
              marginTop: "16px !important",
              marginBottom: "8px !important",
              marginLeft: "8px !important",
            }}
          >
            {t(title)}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} light={!whiteSidenav && !transparentSidenav} />;
      }

      return menuPermission != null ? (
        <Authorization externalId={menuPermission} appearance="display">
          {returnValue}
        </Authorization>
      ) : (
        returnValue
      );
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav }}
    >
      <MDBox component={NavLink} to="/dashboard" sx={{ height: "100px" }}>
        <IAMLogo />
      </MDBox>
      <Divider light={!whiteSidenav && !transparentSidenav} />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
