import { useState, useEffect } from "react";
import { Card, InputAdornment, IconButton } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { MDBox, MDTypography, MDInput, MDLoadingButton } from "components";
import CoverLayout from "pages/Public/Login/layout";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ResetPassword } from "services/Login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DevTool } from "@hookform/devtools";

const PASSWORD_COMPLEXITY_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;

function SubmitPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTempPassword, setShowTempPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleShowTempPassword = () => setShowTempPassword(!showTempPassword);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState,
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [passwordError, setPasswordError] = useState();

  const [searchParams] = useSearchParams();
  const session = searchParams.get("reset_session");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setError("confirmPassword", {
        type: "mismatch",
        message: "Passwords do not match",
      });
      setPasswordError(null);
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
      clearErrors("confirmPassword");
    }
  }, [password, confirmPassword, setError, clearErrors]);

  const getDestinationRef = () => searchParams.get("destinationRef") || "iam";

  const onSubmit = (data) => {
    setIsLoading(true);

    ResetPassword(
      {
        TempPassword: data.tempPassword,
        Password: data.password,
        ConfirmPassword: data.confirmPassword,
        DestinationRef: getDestinationRef(),
      },
      session
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        navigate(`/?destinationRef=${getDestinationRef()}&passwordChanged=true`);
      } else if (response.response.data === "Unauthenticated user!") {
        setPasswordError({
          message: "ERR_MATCH",
        });
        clearErrors("confirmPassword");
      } else {
        setPasswordError({
          message:
            typeof response.response.data === "string" ? response.response.data : "Unhandled error",
        });
        clearErrors("confirmPassword");
      }
    });
  };

  return (
    <CoverLayout coverHeight="50vh">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            {t("submitpassword.resetmessage")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={2}>
            {t("submitpassword.enterpassword")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MDBox component="div" role="form">
              <MDBox mb={4}>
                <Controller
                  name="tempPassword"
                  control={control}
                  rules={{
                    required: t("login.passwordRequired"),
                  }}
                  render={({ field }) => (
                    <MDInput
                      style={{ backgroundColor: "white" }}
                      type={showTempPassword ? "text" : "password"}
                      label={t("tempPassword")}
                      variant="standard"
                      fullWidth
                      id="tempPassword"
                      {...register("tempPassword", { required: true })}
                      InputProps={{
                        endAdornment: field.value.length > 0 && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowTempPassword}
                            >
                              {showTempPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={field.value || ""}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      onBlur={field.onBlur}
                    />
                  )}
                  defaultValue=""
                />
              </MDBox>
              <MDBox mb={4}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: t("login.passwordRequired"),
                  }}
                  render={({ field }) => (
                    <MDInput
                      style={{ backgroundColor: "white" }}
                      type={showPassword ? "text" : "password"}
                      label={t("password")}
                      variant="standard"
                      fullWidth
                      id="password"
                      {...register("password", { required: true })}
                      InputProps={{
                        endAdornment: field.value.length > 0 && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={field.value || ""}
                      onChange={(event) => {
                        if (!PASSWORD_COMPLEXITY_REGEX.test(event.target.value)) {
                          setError("password", {
                            type: "weakPassword",
                            message:
                              "Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long.",
                          });
                        } else if (
                          watch("confirmPassword") &&
                          watch("confirmPassword") === event.target.value
                        ) {
                          clearErrors("confirmPassword");
                          clearErrors("password");
                        } else {
                          setError("confirmPassword", {
                            type: "mismatch",
                            message: "Passwords do not match",
                          });
                          clearErrors("password");
                          setPasswordError(null);
                        }
                        field.onChange(event.target.value);
                      }}
                      onBlur={field.onBlur}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </MDBox>
              <MDBox mt={-2}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {errors.password ? <p>{errors.password.message}</p> : null}
                </MDTypography>
              </MDBox>
              <MDBox mb={4}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: t("login.passwordRequired"),
                  }}
                  render={({ field }) => (
                    <MDInput
                      style={{ backgroundColor: "white" }}
                      type={showConfirmPassword ? "text" : "password"}
                      label={t("resetpassword.confirmpassword")}
                      variant="standard"
                      fullWidth
                      id="confirmPassword"
                      {...register("confirmPassword", { required: true })}
                      onChange={(event) => {
                        if (watch("password") && watch("password") === event.target.value) {
                          clearErrors("confirmPassword");
                        } else {
                          setError("confirmPassword", {
                            type: "mismatch",
                            message: "Passwords do not match",
                          });
                          setPasswordError(null);
                        }
                        field.onChange(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: field.value.length > 0 && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowConfirmPassword}
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={field.value || ""}
                      onBlur={field.onBlur}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </MDBox>
              <MDBox sx={{ height: 0 }} mt={-2}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {passwordError && t(passwordError.message)}{" "}
                  {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
                </MDTypography>
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDLoadingButton
                  variant="gradient"
                  isLoading={isLoading}
                  color="dark"
                  fullWidth
                  type="submit"
                  disabled={
                    !formState.isValid ||
                    !passwordsMatch ||
                    Object.keys(formState.errors).length > 0
                  }
                >
                  {t("submitpassword.reset")}
                </MDLoadingButton>
              </MDBox>
              <MDBox mt={2} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  {t("submitpassword.return")}
                  {"  "}
                  <MDTypography
                    component={Link}
                    to={{
                      pathname: "/*",
                      search: `?destinationRef=${getDestinationRef()}`,
                    }}
                    variant="button"
                    color="dark"
                    fontWeight="medium"
                    textGradient
                  >
                    {t("submitpassword.login")}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
      <DevTool control={control} />
    </CoverLayout>
  );
}

export default SubmitPassword;
