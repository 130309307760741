import React, { useState, useMemo } from "react";
import AuthContext from "context/authContext";

function AuthProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const setUserData = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    setUser(data);
  };

  const removeUserData = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  const authValue = useMemo(
    () => ({ user, setUserData, removeUserData }),
    [user, setUserData, removeUserData]
  );

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
