import { forwardRef } from "react";
import PropTypes from "prop-types";
import { MDBox, MDTypography } from "components";
import MDInputRoot from "./MDInputRoot";

const MDInput = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <MDBox>
    <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
    <MDBox sx={{ height: 0 }}>
      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        {error.message}
      </MDTypography>
    </MDBox>
  </MDBox>
));

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDInput;
