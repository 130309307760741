import { useHTTPClient } from "helper";

const PermissionsService = () => {
  const { get, put, post } = useHTTPClient();

  const getActionPermission = (id, sourceToken) =>
    get(`Permission/getById?permissionId=${id}`, sourceToken);

  const getDataPermission = (id, sourceToken) =>
    get(`PermissionObject/getByPermissionId?permissionId=${id}`, sourceToken);

  const getAllPermissionTypes = () => get("Permission/getAllTypes");

  const updatePermission = (body) => put("Permission/update", body);

  const createPermission = (body) => post("Permission/create", body);

  const getByQuery = ({ page, recordsToTake }, body = {}) =>
    post(`Permission/getByQuery?page=${page}&recordsToTake=${recordsToTake}`, body);

  const createPermissionObjects = (body) => post("PermissionObject/create", body);

  const updatePermissionObjects = (body) => put("PermissionObject/update", body);

  return {
    getActionPermission,
    getDataPermission,
    getAllPermissionTypes,
    updatePermission,
    createPermission,
    getByQuery,
    createPermissionObjects,
    updatePermissionObjects,
  };
};

export default PermissionsService;
