import axios from "axios";
import { iamURL } from "config";

const BASE_URL = "CookiePolicy";

const GET_HEADERS = () => ({
  "Content-Type": "application/json",
});

function CookiePolicyService() {
  const getAllCookies = () =>
    axios.get(`${iamURL}/${BASE_URL}/getAll`, { headers: GET_HEADERS(), withCredentials: true });

  return { getAllCookies };
}

export default CookiePolicyService;
