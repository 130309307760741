import { useEffect } from "react";
import { useAuth } from "helper";
import { useNavigate, useLocation } from "react-router-dom";

const MENU_ROUTES = ["/users", "/roles", "/permissions"];

function AuthGuard({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (user == null) {
      navigate(user?.code ? `/?destinationRef=${user.code}` : "/");
    } else if (
      MENU_ROUTES.includes(pathname) &&
      !user?.actionPermissions
        ?.toLowerCase()
        .split(",")
        .filter((i) => i.includes("mnu"))
        .map((i) => i.substring(3))
        .includes(pathname.substring(1))
    ) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return children;
}

export default AuthGuard;
