import { Helmet } from "react-helmet";

function IAMLogo() {
  const containerStyle = {
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
  };

  const logoStyle = {
    width: "300px",
    height: "150px",
  };

  const logoSvgStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div style={containerStyle}>
        <div style={logoStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            style={logoSvgStyle}
          >
            <rect x="3" y="3" width="18" height="12" rx="1" ry="1" fill="none" />
            <text x="4" y="14" fill="#d5d5e3" fontSize="9" fontFamily="Orbitron " fontWeight="500">
              iam
            </text>
            <rect x="4" y="6.2" width="2" height="2" fill="#3E3E45" />
            <path
              d="M8 4l4 2-4 2-4-2 4-2z"
              transform="scale(0.2) translate(17, 32.4)"
              fill="#d5d5e3"
            />
            <path
              d="M8 2l4 2-4 2-4-2 4-2z"
              transform="scale(0.2) translate(17, 32)"
              fill="#ff0000"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default IAMLogo;
