import { useHTTPClient } from "helper";

const RolesService = () => {
  const { post, put } = useHTTPClient();

  const getByQuery = ({ page, recordsToTake }, body = {}) =>
    post(`Role/getByQuery?page=${page}&recordsToTake=${recordsToTake}`, body);

  const createRole = (body) => post("Role/create", body);

  const addPermission = (body) => put("Role/addPermission", body);

  const updateRole = (body) => put("Role/update", body);

  const removePermission = (body) => post("Role/removePermission", body);

  return { getByQuery, updateRole, createRole, addPermission, removePermission };
};

export default RolesService;
